import React, { createContext, useEffect, useState } from "react";
import auth from "./utils/auth";
import UsersAPI from "./services/UsersAPI";
import { aclsLocalStorageKey } from "./utils/permissions/permission.utils";
// import { useLocation } from "react-router-dom";

export const AuthContext = createContext(null);

const EMPTY_TOKEN = "";

function AuthProvider({ children }) {
  const [token, setToken] = useState(EMPTY_TOKEN);
  const [isAclLoading, setIsAclLoading] = useState(true);
  // let currentLocation = useLocation();

  const getToken = async () => {
    return new Promise((resolve) => {
      try {
        let { token } = auth.getTokenFromUrl();
        if (token) {
          auth.setTokenToStorage(token);
        } else {
          token = auth.getTokenFromStorage();
        }
        if (token) {
          resolve(token);
        } else {
          // auth.logout("NO_TOKEN");
          auth.clearLoginData();
          resolve(EMPTY_TOKEN);
        }
      } catch (e) {
        console.error(e);
        // auth.logout("ERROR");
        auth.clearLoginData();
        resolve(EMPTY_TOKEN);
      }
    });
  };

  useEffect(() => {
    console.log("AuthContext component has mounted or updated.");
    localStorage.removeItem(aclsLocalStorageKey);
    getToken().then(async (foundToken) => {
      if (foundToken) {
        const getAsyncAcls = async () => {
          await UsersAPI.getAcls();
          setIsAclLoading(false);
        };
        getAsyncAcls();
        setToken(foundToken);
        auth.logoutIfRequired(foundToken);
      } else {
        console.log(`DEBUG AuthProvider path ${window.location.pathname}`);

        console.log("DEBUG AuthProvider getLoginUrl");
        if (!auth.isOpenRoute()) {
          window.location = auth.getLoginUrl();
        }
      }
    });
  }, [token]);

  useEffect(() => {}, []);

  if (token) {
    try {
      const user = auth.parseToken(token);
      return (
        <AuthContext.Provider value={user}>{children}</AuthContext.Provider>
      );
    } catch (error) {
      console.error(error);
      auth.logout("ERROR");
    }
  } else if (auth.isOpenRoute()) {
    return children;
    // <AuthContext.Provider value={null}>{children}</AuthContext.Provider>;
  } else {
    return <div className="main-loading">Loading...</div>;
  }
}

export default AuthProvider;
