import React from 'react';
import { Grid, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    fontSize: 22,
  },
  subtitle: {
    fontSize: 16,
    color: 'grey',
    textAlign: 'center'
  },
  circle: {
    width: '50px',
    height: '50px',
    background: 'white',
    borderRadius: '50%',
    border: '0.5px solid',
    margin: '24px 24px'
  },
  link: {
    fontSize: 14,
    color: '#3f51b5 !important',
    paddingLeft: 5,
    cursor: 'pointer',
  },
}));

const VerifyEmail = ({onResendVerification}) => {
  const classes = useStyles();

    return (
      <>
        <Grid item container justify="center">
          <h3 className={classes.subtitle}>Thank you for registering!</h3>
        </Grid>
        <Grid item container justify="center">
          <div className={classes.circle}></div>
        </Grid>
        <Grid item container justify="center">
          <h1 className={classes.title}>Verify your email address</h1>
        </Grid>
        <Grid item container justify="center">
          <h3 className={classes.subtitle}>Please click on the link that has been sent to your email account to verify your email</h3>
        </Grid>
        <Grid item container justify="center" alignItems="center">
          <h3 className={classes.subtitle}>Didn't receive an email?</h3>
          <Link onClick={onResendVerification} className={classes.link}>
            Resend
          </Link>
        </Grid>
      </>
    )
  }

export default VerifyEmail