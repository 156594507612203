import {
  Box,
  FormControlLabel,
  makeStyles,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isArray } from "lodash";
import CheckIcon from "@material-ui/icons/Check";
import MuiAlert from "@material-ui/lab/Alert";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "rgb(0, 97, 129)",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableBodyCell = withStyles((theme) => ({
  head: {
    backgroundColor: "rgb(0, 97, 129)",
    color: theme.palette.common.white,
    verticalAlign: "top",
  },
  body: {
    fontSize: 14,
    verticalAlign: "top",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {},
  tableContainer: {
    maxHeight: "40vh",
    overflowY: "auto",
  },
});

function NameRow({ row }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>
        <Typography component={"span"} variant="h6">
          {row?.name}
        </Typography>
      </Box>
    </Box>
  );
}

function AvailableFormReferralsTable({
  referrals,
  selectedReferral,
  setSelectedReferral,
}) {
  const classes = useStyles();

  const handleReferralChange = (event) => {
    const selectedFormUUID = event.target.value;
    const selected = referrals.find((s) => s?.formUUID === selectedFormUUID);
    setSelectedReferral(selected);
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          size="small"
          className={classes.table}
          aria-label="referrals table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {referrals.map((row) => (
              <StyledTableRow key={row?.formUUID}>
                <StyledTableBodyCell component="th" scope="row">
                  {row?.name ? <NameRow row={row} /> : null}
                </StyledTableBodyCell>

                <StyledTableBodyCell>
                  {row?.formUUID ? (
                    <FormControlLabel
                      value={row?.formUUID}
                      control={<Radio />}
                      checked={row?.formUUID === selectedReferral?.formUUID}
                      onChange={handleReferralChange}
                      label=""
                    />
                  ) : null}
                </StyledTableBodyCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

AvailableFormReferralsTable.propTypes = {
  referrals: PropTypes.array.isRequired,
  selectedReferral: PropTypes.object,
  setSelectedReferral: PropTypes.func.isRequired,
};

export default AvailableFormReferralsTable;
