import { mintProxyUrl } from "../utils/constants";
import { AxiosBackendHelper } from "../utils/AxiosHelper";
import { default as axiosFhirInstance } from "../utils/FhirAxiosHelper";
import { isArray } from "lodash";
import { TenantConfigurationStore } from "../components/Tenants/TenantConfigurationStore";

const axiosInstance = AxiosBackendHelper.newInstance();

export class AdvancedReferralAPI {
  static baseFhirUrl = null;

  static async setBaseUrl() {
    const tenantConf = await TenantConfigurationStore.getTenantConfiguration();
    if (tenantConf?.fhir?.fhirBaseUrl) {
      this.baseFhirUrl = tenantConf?.fhir?.fhirBaseUrl;
    }
  }

  static async getBaseUrl() {
    if (!this.baseFhirUrl) {
      await this.setBaseUrl();
    }
    return this.baseFhirUrl;
  }

  static async getAvailableAdvancedReferrals() {
    const result = await axiosInstance({
      url: `${mintProxyUrl}/api/catalogs?category=FormServiceUUID`,
      method: "GET",
    });
    if (result?.data && isArray(result?.data) && result?.data.length > 0) {
      const catalogAdvancedReferrals = result?.data.map((x) => {
        const identifier = JSON.parse(x?.identifier);
        return {
          formUUID: identifier.formUUID,
          healthcareServiceUUID: identifier?.healthcareServiceUUID,
          name: identifier?.name,
        };
      });
      return catalogAdvancedReferrals;
    }
    return [];
  }

  static async getAvailableAdvancedReferralsHealthcareServices(query) {
    try {
      const url = `${await this.getBaseUrl()}/HealthcareService${query}`;
      const result = await axiosFhirInstance.get(url);
      const found = result?.data?.entry?.map((r) => r?.resource);
      if (found && found.length > 0) {
        return found;
      }
    } catch (error) {
      console.log(error);
    }
    return [];
  }
}
