import ACL, { ALL_WILDCARD, EDIT, MENU, VIEW, ADD, DELETE } from "./ACL";

//Admin ACLs
const adminACL = new ACL("admin");
//Admin Users ACLs
const adminUsersACL = adminACL.newChild("users");

//Admin Invite ACLs
const adminInvitesACL = adminACL.newChild("invites");

//Admin Permissions ACLs
const adminPermissionsACL = adminACL.newChild("permissions");

//CRS ACLs
const mainACL = new ACL("main");

//CRS Dashboard
const crsDashboardACL = mainACL.newChild("dashboard");

//CRS Patient ACLs
const crsPatientACL = mainACL.newChild("patient");
const crsPatientSearchACL = crsPatientACL.newChild("search");

const crsPatientAssessmentsACL = crsPatientACL.newChild("assessments");
const mainPatientTabsACL = crsPatientACL.newChild("tabs");

//CRS Referral
const mainReferralACL = mainACL.newChild("referral");
const mainAdvancedReferralACL = mainACL.newChild("advancedReferral");
mainReferralACL.newChild("identifierDetails");

const mainAcls = {
  ALL: ALL_WILDCARD,
  ADMIN: {
    ALL: adminACL.concat(ALL_WILDCARD),
    VIEW: adminACL.concat(VIEW),
    USERS: {
      ALL: adminUsersACL.concat(ALL_WILDCARD),
      MENU: adminUsersACL.concat(MENU),
      VIEW: adminUsersACL.concat(VIEW),
      ADD: adminUsersACL.concat(ADD),
    },
    INVITE: {
      ALL: adminInvitesACL.concat(ALL_WILDCARD),
      MENU: adminInvitesACL.concat(MENU),
      VIEW: adminInvitesACL.concat(VIEW),
      ADD: adminInvitesACL.concat(ADD),
    },
  },
  MAIN: {
    ALL: mainACL.concat(ALL_WILDCARD),
    DASHBOARD: {
      ALL: crsDashboardACL.concat(ALL_WILDCARD),
      MENU: crsDashboardACL.concat(MENU),
    },
    PATIENT: {
      ALL: crsPatientACL.concat(ALL_WILDCARD),
      MENU: crsPatientACL.concat(MENU),
      ADD: crsPatientACL.concat(ADD),
      VIEW: crsPatientACL.concat(VIEW),
      TABS: {
        ALL: mainPatientTabsACL.concat(ALL_WILDCARD),
        GENERAL: mainPatientTabsACL.concat("general", VIEW),
        DOCUMENTS: mainPatientTabsACL.concat("documents", VIEW),
        ASSESSMENTS: mainPatientTabsACL.concat("assessments", VIEW),
        REFERRALS: mainPatientTabsACL.concat("referrals", VIEW),
        ADVANCED_REFERRALS: mainPatientTabsACL.concat(
          "advancedReferrals",
          VIEW
        ),
        CASES: mainPatientTabsACL.concat("cases", VIEW),
      },
      CARD: {
        VIEW: crsPatientACL.concat("card", VIEW),
      },
      SEARCH: {
        ALL: crsPatientSearchACL.concat(ALL_WILDCARD),
      },
      ASSESSMENTS: {
        ALL: crsPatientAssessmentsACL.concat(ALL_WILDCARD),
        START: crsPatientAssessmentsACL.concat("start"),
        START_NEW_WINDOW: crsPatientAssessmentsACL.concat("startNewWindow"),
        VIEW: crsPatientAssessmentsACL.concat(VIEW),
      },
    },
    REFERRAL: {
      ALL: mainReferralACL.concat(ALL_WILDCARD),
      VIEW: mainReferralACL.concat(VIEW),
      ADD: mainReferralACL.concat(ADD),
      EDIT: mainReferralACL.concat(EDIT),
      MENU: mainReferralACL.concat(MENU),
    },
    ADVANCED_REFERRAL: {
      ALL: mainAdvancedReferralACL.concat(ALL_WILDCARD),
      VIEW: mainAdvancedReferralACL.concat(VIEW),
      ADD: mainAdvancedReferralACL.concat(ADD),
      EDIT: mainAdvancedReferralACL.concat(EDIT),
      MENU: mainAdvancedReferralACL.concat(MENU),
    },
  },
};

const getFlatACLsList = (acls) => {
  const keys = Object.keys(acls);

  return keys.reduce((list, key) => {
    if (typeof acls[key] === "object")
      return [...list, ...getFlatACLsList(acls[key])];

    return [...list, acls[key]];
  }, []);
};

export default mainAcls;
