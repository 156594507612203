import React from "react";
import PropTypes from "prop-types";
import { get, isEmpty } from "lodash";
import ReferralsAPI from "./ReferralsAPI";
import { getDocuments } from "./MintAPI";
import { DocumentUtils } from "./DocumentUtils";
import auth from "../utils/auth";
import { getPatientId } from "./ReferralStates";

const shape = PropTypes.shape;
const string = PropTypes.string;
const number = PropTypes.number;
const bool = PropTypes.bool;
const object = PropTypes.object;
const arrayOf = PropTypes.arrayOf;

const REFERRAL_LINKED_DOCUMENT_SHAPE = shape({
  id: number,
  annotation: string,
  repositoryUniqueId: string.isRequired,
  documentUniqueId: string.isRequired,
  hcid: string.isRequired,
});

function idEquals(idType, attached, document) {
  const docIds = get(document, "identifier", []);
  const idTypeDocId = docIds.find((id) => get(id, "type.text") === idType);
  const idTypeValue = get(idTypeDocId, "value", "");
  return attached[idType] === idTypeValue;
}

let ADVANVED_REFERRAL_IFRAME = null;

export class ReferralUtils {
  static REFERRAL_DETAIL_SHAPE = shape({
    referralId: number.isRequired,
    referringOrganization: string.isRequired,
    referringPatientId: string.isRequired,
    referringMintUserId: string.isRequired,
    parentReferralId: string,
    receivingOrganizationName: string.isRequired,
    receivingMintUserId: string,
    receivingPatientId: string.isRequired,
    serviceCategory: string,
    urgent: bool.isRequired,
    rejected: bool.isRequired,
    deleted: bool.isRequired,
    createTs: string.isRequired,
    givenName: string.isRequired,
    middleName: string,
    familyName: string.isRequired,
    birthdate: string.isRequired,
    sex: string.isRequired,
    description: string.isRequired,
    status: string.isRequired,
    statusComment: string,
    statusTs: string.isRequired,
    addendum: arrayOf(object),
    notes: arrayOf(object),
    attachedMintIheDocuments: arrayOf(REFERRAL_LINKED_DOCUMENT_SHAPE),
    references: arrayOf(object),
  });

  static REFERRAL_INCOMPLETE_DETAIL_SHAPE = shape({
    referralId: number,
    referringOrganization: string,
    referringPatientId: string,
    referringMintUserId: string,
    parentReferralId: string,
    receivingOrganizationName: string,
    receivingMintUserId: string,
    receivingPatientId: string,
    serviceCategory: string,
    urgent: bool,
    rejected: bool,
    deleted: bool,
    createTs: string | Date,
    givenName: string,
    middleName: string,
    familyName: string,
    birthdate: string,
    sex: string,
    description: string,
    status: string,
    statusComment: string,
    statusTs: string,
    addendum: arrayOf(object),
    notes: arrayOf(object),
    attachedMintIheDocuments: arrayOf(REFERRAL_LINKED_DOCUMENT_SHAPE),
    references: arrayOf(object),
  });

  static findLinkedDocuments(documents, referral) {
    return (referral?.attachedMintIheDocuments || []).reduce(
      (foundDocuments, currentAttached) => {
        const foundDocument = (documents || []).find((document) => {
          return (
            idEquals("repositoryUniqueId", currentAttached, document) &&
            idEquals("documentUniqueId", currentAttached, document) &&
            idEquals("hcid", currentAttached, document)
          );
        });
        if (foundDocument) {
          foundDocuments.push({
            ...foundDocument,
            attachmentId: currentAttached.id,
          });
        }
        return foundDocuments;
      },
      []
    );
  }

  static async getReferralWithDocuments(
    patientId,
    referralId,
    documents,
    referral
  ) {
    if (!referral && !referralId) {
      throw new Error(
        "Please provide either the [referral] or the [referralId] parameter."
      );
    }
    const missingDataFetchers = [];
    const fetchedValues = {};
    if (!referral) {
      missingDataFetchers.push(
        new Promise(async (resolve, reject) => {
          try {
            fetchedValues.referral = await ReferralsAPI.getReferral(referralId);
            resolve();
          } catch (error) {
            reject(error);
          }
        })
      );
    }
    if (!documents && patientId) {
      missingDataFetchers.push(
        new Promise(async (resolve, reject) => {
          try {
            fetchedValues.documents = await getDocuments({ patientId });
            resolve();
          } catch (error) {
            reject(error);
          }
        })
      );
    }
    await Promise.all(missingDataFetchers);
    const _referral = fetchedValues.referral || referral;
    let _documents = fetchedValues.documents || documents;
    if (!documents && !patientId) {
      _documents = await getDocuments({
        patientId: getPatientId(auth, _referral),
      });
    }
    const _patientDocuments = DocumentUtils.normalizeDocuments(_documents);
    if (!_referral && referralId && !referral) {
      throw new Error(`Referral with id ${referralId} was not found.`);
    }
    if (!_patientDocuments && patientId && !documents) {
      throw new Error(
        `Documents for Patient ID '${patientId}' were not found.`
      );
    }
    return {
      referral: _referral,
      documents: _patientDocuments,
    };
  }

  static getRetrievalParameters(_fhirDocument) {
    const fhirDocument = _fhirDocument.resource || _fhirDocument;
    let retrievalParameters = {
      ...fhirDocument.identifier
        .filter((id) =>
          ["repositoryUniqueId", "documentUniqueId", "hcid"].includes(
            get(id, "type.text")
          )
        )
        .reduce((params, id) => {
          params[get(id, "type.text")] = id.value;
          return params;
        }, {}),
    };
    if (isEmpty(retrievalParameters)) {
      retrievalParameters = {
        documentUniqueId: fhirDocument.identifier[0].value,
        repositoryUniqueId: fhirDocument.identifier[1].value,
        hcid: fhirDocument.identifier[2].value,
      };
    }
    return retrievalParameters;
  }

  static setPreventionLinkIframe(preventionLinkIframe) {
    ADVANVED_REFERRAL_IFRAME = preventionLinkIframe;
  }

  static getPreventionLinkIframe() {
    return ADVANVED_REFERRAL_IFRAME;
  }

  static patientDataToReferral(
    patientData,
    { urgent, serviceCategory, receivingOrganizationName, description }
  ) {
    return {
      referringPatientId: get(patientData, "fhirPatient.id", ""),
      givenName: get(patientData, "fhirPatient.name[0].given", []).join(" "),
      familyName: get(patientData, "fhirPatient.name[0].family", ""),
      birthdate: get(patientData, "fhirPatient.birthDate", ""),
      sex:
        { male: "M", female: "F" }[get(patientData, "fhirPatient.gender")] ||
        "O",
      description,
      urgent,
      serviceCategory,
      referringOrganization: auth.user().userinfo.orgName,
      receivingOrganizationName,
      status: "NEW",
      createTs: new Date(),
    };
  }

  static parseMintError(errorMessage) {
    try {
      const regex = new RegExp(
        `Error \\(Status\: [0-9]+, Log ID\: (.+)\\) \- (.+)`,
        "g"
      );
      const result = regex.exec(errorMessage);
      if (!result || result.length !== 3) {
        return errorMessage;
      }
      return {
        logId: result[1],
        ...JSON.parse(result[2]),
      };
    } catch (error) {
      return errorMessage;
    }
  }

  static errorTransformer(errorMessage) {
    const errorDetails = ReferralUtils.parseMintError(errorMessage);
    if (typeof errorDetails !== "object") {
      return errorMessage;
    }
    return (
      <ul
        style={{
          fontFamily: "sans-serif",
          marginTop: "-10pt",
          marginLeft: "-10pt",
          marginBottom: "-6pt",
        }}
      >
        {errorDetails.errors.map((error) => (
          <li>
            <span
              style={{
                fontSize: "14pt",
                color: "red",
              }}
            >
              {error.detail}
            </span>
            <ul>
              <li>Status: {error.status}</li>
              <li>
                Log ID:
                <div>
                  <span
                    style={{
                      fontFamily: "monospace",
                      fontSize: "9pt",
                      border: "1px dotted lightgray",
                      display: "inline-block",
                      padding: "2pt 4pt",
                      marginTop: "2pt",
                    }}
                  >
                    {errorDetails.logId}
                  </span>
                </div>
              </li>
            </ul>
          </li>
        ))}
      </ul>
    );
  }

  static isNilOrEmpty(str) {
    return ["null", "undefined"].includes(String(str)) || str.trim() === "";
  }

  static getPatientId(patientId, referral) {
    if (!ReferralUtils.isNilOrEmpty(patientId)) {
      return patientId;
    }
    if (!ReferralUtils.isNilOrEmpty(referral?.receivingPatientId)) {
      return referral.receivingPatientId;
    }
    if (!ReferralUtils.isNilOrEmpty(referral?.referringPatientId)) {
      return referral.referringPatientId;
    }
  }

  static getPatientFullName(referral) {
    const { familyName, givenName, middleName } = referral?.patientDemographics;
    return `${givenName || ""} ${middleName || ""} ${familyName || ""}`;
  }
}
