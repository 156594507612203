import {
  Box,
  Button,
  createMuiTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import colors from "../../../utils/colors";
import { checkAclValidation } from "../../../utils/permissions/permission.utils";
import mainAcls from "../../../utils/permissions/mainAcls";
import AvailableFormReferralsTable from "./AvailableFormReferralsTable";

const theme = createMuiTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        left: "48%",
        position: "absolute",
        bottom: "10px",
      },
      svg: {
        color: colors.baseBlue,
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    // minWidth: 500,
  },
  checkBox: {
    margin: theme.spacing(1),
    marginBottom: 0,
  },
  right: {
    marginLeft: "auto",
  },
  cancelButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: "auto",
  },
  searchButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: "auto",
  },
  openButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: "auto",
  },
}));

function FormReferralsSelectionDialog({
  open,
  handleClose,
  handleOpenReferral,
  referrals,
  selectedReferral,
  setSelectedReferral,
}) {
  const classes = useStyles();

  const handleResetAndClose = () => {
    setSelectedReferral(null);
    handleClose();
  };

  return (
    <>
      <Dialog
        maxWidth={"lg"}
        open={open}
        onClose={handleResetAndClose}
        aria-labelledby="services-dialog"
        scroll="paper"
      >
        <DialogTitle id="advanced-referral-selection-dialog-title">
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>Advanced Referral Selection</Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <AvailableFormReferralsTable
            referrals={referrals}
            selectedReferral={selectedReferral}
            setSelectedReferral={setSelectedReferral}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" type="submit" onClick={handleResetAndClose}>
            Cancel
          </Button>

          {checkAclValidation({
            acls: [mainAcls.MAIN.ADVANCED_REFERRAL.ADD],
          }) ? (
            <Button
              color="primary"
              type="submit"
              disabled={!selectedReferral}
              onClick={handleOpenReferral}
            >
              Open
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </>
  );
}

FormReferralsSelectionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleOpenReferral: PropTypes.func.isRequired,
  referrals: PropTypes.array.isRequired,
  selectedReferral: PropTypes.any,
  setSelectedReferral: PropTypes.func.isRequired,
};

export default FormReferralsSelectionDialog;
