import React, { useEffect, useRef, useState } from "react";

export function EmbeddedForm({
  formRendererUrl,
  formUUID,
  size,
  formParameters,
  onFormLoaded,
  submitError,
}) {
  const iframe = useRef(null);
  const [formLoaded, setFormLoaded] = useState(false);

  const messageListener = useRef(async (event) => {
    const formEvent = event.data;
    // console.log("DEBUG formEvent: ", formEvent);
    if (formEvent.type === "renderer-ready" && formEvent.payload === true) {
      iframe.current.contentWindow.postMessage(
        {
          type: "parameters-sent",
          payload: formParameters,
        },
        "*"
      );
    } else if (formEvent.type === "form-loaded" && formEvent.payload === true) {
      onFormLoaded(iframe.current);
      setFormLoaded(true);
    }
  });

  useEffect(() => {
    const listener = messageListener.current;
    window.addEventListener("message", listener);
    return () => {
      window.removeEventListener("message", listener);
    };
  }, []);

  useEffect(() => {
    if (submitError) {
      iframe.current.contentWindow.postMessage(
        {
          type: "submit-error",
          payload: submitError,
        },
        "*"
      );
    }
  }, [submitError]);

  return (
    <iframe
      ref={iframe}
      title="Embedded Form"
      src={`${formRendererUrl}?formUUID=${formUUID}`}
      style={{
        margin: "0 auto",
        display: "block",
        border: "none",
        width: formLoaded ? size.width : 0,
        height: formLoaded ? size.height : 0,
      }}
    />
  );
}
