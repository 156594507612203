import React, { useEffect, useRef, useState } from "react";
import { Button, Grid, Link } from "@material-ui/core";
import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import colors from "../../utils/colors";
import MobileStepper from "@material-ui/core/MobileStepper";
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import EmailFormConfirm from "./EmailFormConfirm";
import DetailsFormConfirm from "./DetailsFormConfirm";
import VerifyEmail from "./VerifyEmail";
import { useParams, withRouter } from "react-router-dom";
import InvitesAPI from "../../services/InvitesAPI";

const theme = createMuiTheme({
  overrides: {
    MuiInputBaseMultiline: {
      backgroundColor: "white",
    },
    MuiCircularProgress: {
      root: {
        left: "50%",
        position: "absolute",
        top: "-1.3rem",
      },
      svg: {
        color: colors.baseBlue,
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#F5F5F5",
    height: "100vh",
    padding: "100px 25% 0 25%",
    margin: "0px",
  },
  formulary: {
    border: "0.1px solid #dbdbdb",
    borderRadius: "2px",
    backgroundColor: "white",
    padding: "32px !important",
    boxShadow: "0px 0px 10px 1px rgba(219,219,219,1)",
  },
  wlcTitle: {
    fontWeight: 600,
    fontSize: 20,
  },
  subTitle: {
    fontWeight: 600,
    textAlign: "center",
    margin: 0,
    fontSize: 18,
  },
  formularyTitle: {
    fontSize: 16,
    fontWeight: 500,
    color: "grey",
    textAlign: "center",
  },
  link: {
    fontSize: 14,
    color: "#3f51b5 !important",
    textDecoration: "none !important",
    paddingLeft: 5,
  },
  button: {
    width: "100%",
  },
  error: {
    marginTop: "1rem",
  },
}));

const ConfirmInvite = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [startEnd, setStartEnd] = useState("start");
  const [disableButton, setDisableButton] = useState(true);
  const [tempToken, setTempToken] = useState("");
  const [tempEmail, setTempEmail] = useState("");
  const [error, setError] = useState("");
  const [inviteExpirationChecked, setInviteExpirationChecked] = useState(false);
  const [inviteExpiredError, setInviteExpiredError] = useState("");
  const [resendingMessage, setResendingMessage] = useState(false);
  const [resendMessage, setResendMessage] = useState("");
  const userDetails = useRef({
    email: "",
    firstName: "",
    lastName: "",
  });

  const { idInvite } = useParams();

  useEffect(() => {
    InvitesAPI.verifyInviteExpiration(idInvite)
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.message ?? "Could not create invite";
        setInviteExpiredError(errorMessage);
      })
      .finally(() => setInviteExpirationChecked(true));
  }, [idInvite]);

  const matchesSm = useMediaQuery("(max-width:600px)");

  const handleNext = (step) => {
    if (step) {
      setStartEnd(step);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    setDisableButton(true);
  };

  const checkEmailToken = async (email) => {
    try {
      const response = await InvitesAPI.confirmInviteEmail(idInvite, email);
      const {
        token,
        email: userDetailsEmail,
        firstName,
        lastName,
      } = response.data;
      setTempToken(token);
      userDetails.current = { email: userDetailsEmail, firstName, lastName };
      return response.data;
    } catch (error) {
      console.error("error", error);
      const errorMessage =
        error?.response?.data?.message ?? "Could not create invite";
      setError(errorMessage);
    }
  };

  const updateInvite = async (updateInfo) => {
    setTempEmail(updateInfo.email);
    try {
      await InvitesAPI.updateInvite({
        token: tempToken,
        email: updateInfo.email,
        firstName: updateInfo.firstName,
        lastName: updateInfo.lastName,
      });
    } catch (error) {
      console.error("error", error);
      const errorMessage =
        error?.response?.data?.message ?? "Error updating invite";
      setInviteExpiredError(errorMessage);
      setError(errorMessage);
    }
  };

  const resendVerification = async () => {
    try {
      setError("");
      setResendMessage("");
      setResendingMessage(true);
      await InvitesAPI.resendVerificationEmail(tempEmail);
      setResendingMessage(false);
      setResendMessage("Verification email sent successfully.");
    } catch (error) {
      console.error("error", error);
      setResendingMessage(false);
      setError(`${error.message}: ${error.response?.data}`);
    }
  };

  const inviteIsValid = inviteExpirationChecked && !inviteExpiredError;

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={6} className={classes.root} direction="column">
        <Grid container justify="center">
          <div style={{ position: "relative " }}>
            <img
              src={process.env.REACT_APP_BRANDING_LOGO_VERTICAL}
              alt="logo"
              style={{ marginBottom: "50px" }}
            />
            {!inviteExpirationChecked && (
              <CircularProgress
                size={130}
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                }}
              />
            )}
          </div>
          <Grid
            item
            className={classes.formulary}
            container
            direction="column"
            spacing={3}
          >
            {inviteExpirationChecked && inviteExpiredError && (
              <MuiAlert severity="error" className={classes.error}>
                <b>{inviteExpiredError}</b>
              </MuiAlert>
            )}
            {inviteIsValid &&
            (startEnd === "start" || startEnd === "process") ? (
              <>
                <Grid
                  item
                  container
                  justify={matchesSm ? "center" : "flex-start"}
                >
                  <h1 className={classes.wlcTitle}>
                    Welcome to Patient Viewer.
                  </h1>
                </Grid>
                <Grid item container justify="center">
                  <h3 className={classes.subTitle}>
                    You have been invited to use Provider Window.
                  </h3>
                </Grid>
              </>
            ) : null}
            {inviteIsValid && startEnd === "start" ? (
              <Grid container justify="center" spacing={3}>
                <Grid item container justify="center">
                  <h3 className={classes.formularyTitle}>
                    Let's get your account set up. It will take you a minute.
                  </h3>
                </Grid>
                <Grid item container justify="center" xs={12} md={5}>
                  <Button
                    size="small"
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={() => handleNext("process")}
                  >
                    <PlayArrowOutlinedIcon style={{ marginRight: 5 }} />
                    <p style={{ marginTop: 2 }}>Start</p>
                  </Button>
                </Grid>
              </Grid>
            ) : null}
            {inviteIsValid && startEnd === "process" && activeStep === 0 ? (
              <EmailFormConfirm
                checkEmailToken={checkEmailToken}
                handleNext={handleNext}
                setDisableButton={setDisableButton}
                disableButton={disableButton}
              />
            ) : null}
            {inviteIsValid && startEnd === "process" && activeStep === 1 ? (
              <DetailsFormConfirm
                updateInvite={updateInvite}
                handleNext={handleNext}
                setDisableButton={setDisableButton}
                disableButton={false}
                userDetails={userDetails.current}
              />
            ) : null}
            {inviteIsValid && startEnd === "end" ? (
              <VerifyEmail onResendVerification={resendVerification} />
            ) : null}
            {inviteIsValid && startEnd === "process" ? (
              <Grid item xs={12} container justify="center">
                <MobileStepper
                  variant="dots"
                  steps={2}
                  position="static"
                  activeStep={activeStep}
                />
              </Grid>
            ) : null}
            <div style={{ position: "relative" }}>
              {resendingMessage && (
                <ThemeProvider theme={theme}>
                  <div>
                    <CircularProgress />
                  </div>
                </ThemeProvider>
              )}
              {(error || resendMessage) && (
                <MuiAlert
                  severity={error ? "error" : "success"}
                  className={classes.error}
                >
                  {error || resendMessage}
                </MuiAlert>
              )}
            </div>
          </Grid>
          <Grid item container justify="flex-end" style={{ marginTop: "30px" }}>
            <Link className={classes.link}>Need assistance?</Link>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default withRouter(ConfirmInvite);
