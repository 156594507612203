import { usersUrl } from "../utils/constants";
import { ErrorUtils } from "../utils/exceptions";
import { AxiosBackendHelper } from "../utils/AxiosHelper";
import { aclsLocalStorageKey } from "../utils/permissions/permission.utils";

const axiosInstance = ErrorUtils.configureAxiosForMintErrors(
  AxiosBackendHelper.newInstance()
);

export default class UsersAPI {
  static async getUsers(status) {
    const result = await axiosInstance.get(usersUrl, {
      params: {
        status,
      },
    });
    return ErrorUtils.toEmptyArray(result);
  }

  static async getUser(userId) {
    const result = await axiosInstance.get(`${usersUrl}/${userId}/' : ''}`);
    return result?.data;
  }

  static async getAcls() {
    try {
      localStorage.removeItem(aclsLocalStorageKey);
      const { data } = await axiosInstance.get(`/api/auth/acls`);
      if (!data?.acls) return;
      localStorage.setItem(aclsLocalStorageKey, JSON.stringify(data?.acls));
    } catch (e) {
      console.log("error:", e);
    }
  }

  static async getRoles() {
    try {
      const { data } = await axiosInstance.get(`/api/auth/roles`);
      return data;
    } catch (e) {
      console.log("error:", e);
    }
  }
}
