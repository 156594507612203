import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { ReferralUtils } from "../../../services";
import { EmbeddedForm } from "../Forms/EmbeddedForm";
import { referralsFormRendererUrl } from "../../../utils/constants";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflow: "hidden",
  },
  programsSelect: {
    margin: theme.spacing(1),
    minWidth: 850,
    maxWidth: 850,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  submitProgramsButton: {
    marginTop: theme.spacing(2.8),
  },
}));

const ProgramTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);

function FormReferralsDialog(props) {
  const classes = useStyles();
  const {
    formUUID,
    size,
    // referralDefaults,
    // services,
    // selectedProgramsIds,
    // setSelectedProgramsIds,
    // selectedPrograms,
    // showPrograms,
  } = props;

  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);
  const [formLoaded, setFormLoaded] = useState(false);

  const reset = () => {
    setSubmitButtonPressed(false);
    setFormLoaded(false);
  };

  const loadForm = () => {
    setSubmitButtonPressed(true);
  };

  useEffect(() => {
    loadForm();
  }, []);

  const handleFormLoaded = (preventionLinkIframe) => {
    console.log("DEBUG handleFormLoaded");
    ReferralUtils.setPreventionLinkIframe(preventionLinkIframe);
    setFormLoaded(true);
  };

  return (
    <div>
      <Dialog
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
        maxWidth={false}
        open={props.open}
        onClose={props.handleClose}
        // onEnter={reset}
      >
        <DialogTitle id="form-dialog-title">New Advanced Referral</DialogTitle>
        <DialogContent>
          {submitButtonPressed && (
            <div style={{ marginTop: formLoaded ? "1rem" : "0" }}>
              <EmbeddedForm
                formUUID={formUUID}
                formRendererUrl={referralsFormRendererUrl}
                size={{ ...size, height: "530px" }}
                formParameters={
                  {
                    // ...pick(referral, [
                    //   "givenName",
                    //   "middleName",
                    //   "familyName",
                    //   "birthdate",
                    // ]),
                    // streetAddress: get(
                    //   props,
                    //   "patientData.fhirPatient.address[0].line",
                    //   []
                    // ).join(" "),
                    // city: get(props, "patientData.fhirPatient.address[0].city"),
                    // state: get(props, "patientData.fhirPatient.address[0].state"),
                    // zip: get(
                    //   props,
                    //   "patientData.fhirPatient.address[0].postalCode"
                    // ),
                    // programs: selectedPrograms.map(
                    //   (x) => x.healthcareService.name
                    // ),
                  }
                }
                onFormLoaded={handleFormLoaded}
                submitError={props.submitError}
              />
            </div>
          )}
          <Backdrop className={classes.backdrop} open={!!props.showBackdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

FormReferralsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  formUUID: PropTypes.string.isRequired,
  size: PropTypes.object.isRequired,
  patientData: PropTypes.object.isRequired,
  submitError: PropTypes.instanceOf(Error),
  // referralDefaults: PropTypes.object.isRequired,
  // showPrograms: PropTypes.bool,
};

export default FormReferralsDialog;
