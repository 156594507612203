import { Container, ThemeProvider } from "@material-ui/core";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory, useParams, withRouter } from "react-router-dom";
import { theme } from "../theme";
import styles from "../../../styles/viewer.module.css";
import { AdvancedReferralAPI } from "../../../services/AdvancedReferralsAPI";
import { isArray } from "lodash";
import {
  FhirReferralActionButton,
  FhirReferralActions,
} from "../FhirReferrals";
import useReferrals from "../FhirReferrals/useReferrals";
import FhirReferralsList from "../FhirReferrals/FhirReferralsList";
import { Context } from "../../../store/AppStore";
import FormReferralsSelectionDialog from "./FormReferralsSelectionDialog";
import FormReferralsDialog from "./FormReferralsDialog";
import { patientDataStore } from "../../../store/PatientDataStore";
import FormRendererAPI from "../../../services/FormRendererAPI";
import { FhirReferralsAPI, ReferralsAPI } from "../../../services";
import { useStateRef } from "../../../utils/useStateRef";
import moment from "moment";
import { getUserOrgByName } from "../FhirReferrals/utils";
import auth from "../../../utils/auth";

const defaultServiceCategory = "advanced-referral-service-provider";

const AdvancedReferralsViewer = (props) => {
  const [, dispatch] = useContext(Context);
  const history = useHistory();
  const [error, setError] = useState("");
  const [submitError, setSubmitError] = useState(null);
  const { patientId } = useParams();
  const [patientData, setPatientData] = useState(null);

  const [selectedReferral, setSelectedReferral, selectedReferralRef] =
    useStateRef(null);
  const [
    availableAdvancedReferrals,
    setAvailableAdvancedReferrals,
    availableAdvancedReferralsRef,
  ] = useStateRef([]);
  const [
    availableAdvancedReferralsHealthcareServices,
    setAvailableAdvancedReferralsHealthcareServices,
    availableAdvancedReferralsHealthcareServicesRef,
  ] = useStateRef([]);
  const [referralSelectionDialogOpen, setReferralSelectionDialogOpen] =
    useState(false);
  const [referralDialogOpen, setReferralDialogOpen] = useState(false);
  const [referralDialogSize, setReferralDialogSize] = useState(null);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [isReferralDocumentDialogOpen, setIsReferralDocumentDialogOpen] =
    useState(false);

  const handleNewReferralClick = () => {
    setReferralSelectionDialogOpen(true);
  };

  const handleReferralSelectionDialogClosed = () => {
    setReferralSelectionDialogOpen(false);
    // setSelectedReferral(null);
  };

  const showAllReferrals = false;

  const {
    referrals,
    serviceCategories,
    patient,
    createReferral,
    error: referralsError,
  } = useReferrals({
    patientId,
    showAllReferrals,
  });

  useEffect(() => {
    const listener = messageListener.current;

    if (referralDialogSize) {
      window.addEventListener("message", listener);
    }

    return () => {
      window.removeEventListener("message", listener);
    };
  }, [referralDialogSize]);

  const handleGetAvailableAdvancedReferrals = useCallback(async () => {
    try {
      const data = await AdvancedReferralAPI.getAvailableAdvancedReferrals();
      if (data && isArray(data)) {
        setAvailableAdvancedReferrals(data);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleGetAvailableAdvancedReferralsHealthcareServices =
    useCallback(async () => {
      try {
        const data =
          await AdvancedReferralAPI.getAvailableAdvancedReferralsHealthcareServices(
            `?service-category=${defaultServiceCategory}`
          );
        console.log("DEBUG data: ", data);
        if (data && isArray(data)) {
          setAvailableAdvancedReferralsHealthcareServices(data);
        }
      } catch (error) {
        console.log(error);
      }
    }, []);

  const showNewReferralButton = useMemo(() => {
    if (
      availableAdvancedReferrals &&
      isArray(availableAdvancedReferrals) &&
      availableAdvancedReferrals.length > 0 &&
      availableAdvancedReferralsHealthcareServices &&
      isArray(availableAdvancedReferralsHealthcareServices) &&
      availableAdvancedReferralsHealthcareServices.length > 0 &&
      patientData
    ) {
      return true;
    }
    return false;
  }, [
    availableAdvancedReferrals,
    patientData,
    availableAdvancedReferralsHealthcareServices,
  ]);

  const showReferralDialog = useMemo(() => {
    if (
      selectedReferral &&
      selectedReferral?.formUUID &&
      patientData &&
      referralDialogSize &&
      availableAdvancedReferrals &&
      isArray(availableAdvancedReferrals) &&
      availableAdvancedReferrals.length > 0 &&
      availableAdvancedReferralsHealthcareServices &&
      isArray(availableAdvancedReferralsHealthcareServices) &&
      availableAdvancedReferralsHealthcareServices.length > 0
    ) {
      return true;
    }
    return false;
  }, [selectedReferral, patientData, referralDialogSize]);

  useEffect(() => {
    handleGetAvailableAdvancedReferrals();
    handleGetAvailableAdvancedReferralsHealthcareServices();
  }, []);

  useEffect(() => {
    patientDataStore
      .getPatientData(patientId)
      .then((patientData) => setPatientData(patientData));
  }, [patientId]);

  const handleReferralClick = (referral) => {
    if (referral) {
      dispatch({ type: "UPDATE_REFERRAL", payload: referral });
      if (showAllReferrals) {
        history.push(`/referrals/${referral.id}`);
      } else {
        history.push(
          `/patient/search/${patientId}/info/1/referrals/${referral.id}`
        );
      }
    }
  };

  const handleOpenNewReferral = async () => {
    if (selectedReferral?.formUUID) {
      const result = await FormRendererAPI.getReferralFormSize(
        selectedReferral?.formUUID
      );

      if (result && result?.width && result?.height) {
        setReferralDialogSize(result);
        // handleReferralSelectionDialogClosed();
        setReferralDialogOpen(true);
      }
    }
  };

  const handleNewReferralDialogClosed = () => {
    setReferralDialogOpen(false);
    setSelectedReferral(null);
  };

  const getReferralPayload = (userOrganization, formResponse) => {
    console.log(
      "DEBUG selectedReferralRef.current: ",
      selectedReferralRef.current
    );

    console.log(
      "DEBUG availableAdvancedReferralsHealthcareServices.current: ",
      availableAdvancedReferralsHealthcareServicesRef.current
    );

    const selectedService =
      availableAdvancedReferralsHealthcareServicesRef.current.find(
        (x) => x?.id === selectedReferralRef.current.healthcareServiceUUID
      );

    console.log("DEBUG selectedService: ", selectedService);
    console.log("DEBUG selectedService.type: ", selectedService.type);
    console.log("DEBUG selectedService.type[0]: ", selectedService.type[0]);

    // const selectedService = selectedServicesRef.current[0];

    // if (!selectedService.organization) {
    //   console.log("DEBUG missing organization");
    //   return;
    // }

    // console.log("DEBUG performer: ", performer);

    const category = [
      {
        coding: [selectedService.type[0].coding[0]],
        text: selectedService.type[0].text,
      },
    ];

    const metaTags = [
      {
        code: "ph-form-source-uri",
        display: formResponse?.url,
        system:
          "https://terminology.patientholistics.app/fhir/CodeSystem/ph-meta-tags",
      },
      {
        code: "ph-form-source-uuid",
        display: formResponse?.uuid,
        system:
          "https://terminology.patientholistics.app/fhir/CodeSystem/ph-meta-tags",
      },
    ];

    const payload = {
      resourceType: "ServiceRequest",
      subject: {
        reference: `Patient/${patientId}`,
      },
      requester: {
        reference: `Organization/${userOrganization.id}`,
        display: userOrganization.name,
      },
      performer: [
        {
          reference: `HealthcareService/${selectedService.id}`,
          display: selectedService.name,
        },
      ],
      status: "draft",
      intent: "plan",
      category,
      occurrenceDateTime: moment().toISOString(),
      meta: {
        tag: metaTags,
      },
    };

    return payload;
  };

  const saveFormResponse = async (
    formResponse,
    relatedPatientId,
    referralId
  ) => {
    const base64FormResponse = btoa(JSON.stringify(formResponse));

    const binaryPayload = {
      contentType: "application/json",
      data: base64FormResponse,
      resourceType: "Binary",
    };

    const createdBinary = await FhirReferralsAPI.createBinary(binaryPayload);

    console.log("DEBUG createdBinary: ", createdBinary);

    if (!createdBinary?.id) return null;

    const currentDate = new Date().toISOString();

    const patientReference = `Patient/${relatedPatientId}`;

    const documentReferencePayload = {
      content: [
        {
          attachment: {
            contentType: `${createdBinary?.contentType}`,
            creation: currentDate,
            title: `AssessmentResponse_${currentDate}`,
            url: `${process.env.REACT_APP_FHIR_BASE_URL}/Binary/${createdBinary?.id}`,
          },
        },
      ],
      masterIdentifier: {
        use: "official",
        value: "value document",
      },
      resourceType: "DocumentReference",
      status: "current",
      subject: {
        reference: `Patient/${relatedPatientId}`,
      },
      context: {
        sourcePatientInfo: {
          reference: patientReference,
          type: "Patient",
        },
        related: [
          {
            reference: `ServiceRequest/${referralId}`,
            type: "ServiceRequest",
          },
        ],
      },
    };

    const createdDocumentReference =
      await FhirReferralsAPI.createDocumentReference(documentReferencePayload);

    console.log("DEBUG createdDocumentReference: ", createdDocumentReference);

    if (!createdDocumentReference?.id) return null;

    return createdDocumentReference;
  };

  const handleReferralSave = async (formEvent) => {
    const formResponse =
      formEvent?.type === "form-submitted" && formEvent?.payload
        ? formEvent.payload
        : null;

    if (!formResponse) {
      return;
    }

    let orgs = [];
    const responseOrganizations = await FhirReferralsAPI.getOrganizations();
    if (responseOrganizations?.organizations) {
      orgs = responseOrganizations.organizations;
    }

    const userOrganization = getUserOrgByName(
      auth.user().userinfo.orgName,
      orgs
    );

    if (!userOrganization) {
      setError(
        `Organization with name ${auth.user().userinfo.orgName} does not exist`
      );
      return;
    }

    if (!patientId) {
      console.log("DEBUG: patient not defined");
      return "";
    }

    console.log("DEBUG formResponse: ", formResponse);

    const linkedDocuments = formResponse["linked_documents"];
    if (linkedDocuments) {
      delete formResponse["linked_documents"];
    }

    try {
      setShowBackdrop(true);
      const newReferralPayload = getReferralPayload(
        userOrganization,
        formResponse
      );
      console.log("DEBUG newReferralPayload: ", newReferralPayload);
      const createdReferral =
        await FhirReferralsAPI.createPreventionLinkReferral(newReferralPayload);
      for (const retrievalParameters of linkedDocuments || []) {
        await ReferralsAPI.linkDocument(
          createdReferral?.id,
          retrievalParameters
        );
      }
      const savedResponse = await saveFormResponse(
        formResponse,
        patientId,
        createdReferral?.id
      );

      // await loadReferralData();
      setShowBackdrop(false);
      setReferralDialogOpen(false);
      history.push(
        `/patient/search/${patientId}/info/1/referrals/${createdReferral?.id}`
      );
      return "";
    } catch (error) {
      setSubmitError(error);
      setShowBackdrop(false);
      return error.message;
    }
  };

  const messageListener = useRef(async (event) => {
    const formEvent = event.data;
    if (formEvent.type === "form-submitted") {
      await handleReferralSave(formEvent);
    }
    if (formEvent.type === "link-documents") {
      setIsReferralDocumentDialogOpen(true);
    }
  });

  if (error) {
    return (
      <ThemeProvider theme={theme}>
        <Container className={styles.errorContainer}>{error}</Container>
      </ThemeProvider>
    );
  }

  return (
    <Fragment>
      {referralSelectionDialogOpen ? (
        <FormReferralsSelectionDialog
          referrals={availableAdvancedReferrals}
          open={referralSelectionDialogOpen}
          handleOpenReferral={handleOpenNewReferral}
          handleClose={handleReferralSelectionDialogClosed}
          selectedReferral={selectedReferral}
          setSelectedReferral={setSelectedReferral}
        />
      ) : null}

      {showReferralDialog ? (
        <FormReferralsDialog
          open={referralDialogOpen}
          handleClose={handleNewReferralDialogClosed}
          formUUID={selectedReferral?.formUUID}
          size={referralDialogSize}
          patientData={patientData}
          showBackdrop={showBackdrop}
          submitError={submitError}
          // referralDefaults={referralDefaults}
          // services={services}
          // selectedProgramsIds={selectedServicesIds}
          // setSelectedProgramsIds={setSelectedServicesIds}
          // selectedPrograms={selectedServices}
        />
      ) : null}

      <div className={styles.documentViewer}>
        {showNewReferralButton ? (
          <FhirReferralActions>
            <FhirReferralActionButton
              text="Create Referral"
              action={handleNewReferralClick}
            />
          </FhirReferralActions>
        ) : null}
        <FhirReferralsList
          showAllReferrals={showAllReferrals}
          referrals={referrals}
          onReferralClick={handleReferralClick}
        />
      </div>
    </Fragment>
  );
};

export default withRouter(AdvancedReferralsViewer);
